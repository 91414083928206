import * as React from "react";
import {
    Flex,
    Heading,
    useAuthenticator
} from '@aws-amplify/ui-react';
import {useLocation, useNavigate} from "react-router-dom";
import {useFlutterwave, closePaymentModal} from 'flutterwave-react-v3';
import {ActionCard} from "../ui-components";
import {useState} from "react";

export function Payment(props) {
    const {state} = useLocation();
    const {id, total, currency} = state;
    const navigate = useNavigate();
    const {user} = useAuthenticator((context) => [
        context.user,
    ]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isPaused, setIsPaused] = useState(false);


    const config = {
        public_key: process.env.REACT_APP_FW_PUBLIC_KEY,
        tx_ref: id,
        amount: total,
        currency: currency,
        customer: {
            email: user.attributes.email,
        },
        customizations: {
            title: 'Payfrica payment',
            description: '',
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    const launchFlutterwave = () => {
        console.log('starting flutterwave');
        console.log(config);
        console.log('state', state);
        handleFlutterPayment({
            callback: (response) => {
                console.log(response);
                closePaymentModal() // this will close the modal programmatically
                setIsPaused(false);
                setIsSuccess(true);
            },
            onClose: () => {
                console.log('onClose called');
                setIsPaused(true);
            },
        });
    }

    React.useEffect(() => {
        const getData = setTimeout( () => {
            launchFlutterwave();
        }, 250);

        return () => clearTimeout(getData);
    }, [])


    return (
        <>
            <Heading level={1}>Payment</Heading>
            <Flex
                gap="24px"
                direction="column"
                width="auto"
                justifyContent="flex-start"
                alignItems="stretch"
                position="relative"
                padding="24px 0px 24px 0px"
            >
                {isPaused &&
                    <ActionCard
                        icon={"warning"}
                        label={"Your transfer is incomplete. Would you like to continue with payment?"}
                        buttonLabel={"Continue with payment"}
                        action={() => launchFlutterwave()}
                        width={"auto"}
                    ></ActionCard>
                }
                {isSuccess &&
                    <ActionCard
                        icon={"tick"}
                        label={"Your transfer is in progress. You will be notified when the transfer is complete."}
                        buttonLabel={"Close"}
                        action={() => navigate('/')}
                        width={"auto"}
                    ></ActionCard>
                }
                {!isSuccess && !isPaused &&
                    <ActionCard
                        icon={"transfer"}
                        label={"The payment screen should appear. If not, please try opening here."}
                        buttonLabel={"Open Payment"}
                        action={() => launchFlutterwave()}
                        width={"auto"}
                    ></ActionCard>
                }
            </Flex>
        </>
    );
}