/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      email
      invitationCode
      name
      ownerId
      invitationState
      acceptedAt
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(limit: $limit, nextToken: $nextToken) {
      items {
        email
        invitationCode
        name
        ownerId
        invitationState
        acceptedAt
        createdAt
        updatedAt
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getTransfer = /* GraphQL */ `
  query GetTransfer($id: ID!) {
    getTransfer(id: $id) {
      id
      transferType
      ownerId
      transferTypeAndId
      toBankCode
      toBankName
      toAccountNumber
      toAccountName
      note
      transferValue
      serviceFee
      total
      currency
      transferState
      receiveProviderFee
      sendProviderFee
      paymentProvider
      paymentLink
      paymentLinkExpiresAt
      completedAt
      executions {
        id
        transferType
        ownerId
        transferTypeAndId
        serviceProvider
        providerReference
        providerFee
        transferState
        receipt
        createdAt
        updatedAt
        _deleted
      }
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const listTransfers = /* GraphQL */ `
  query ListTransfers(
    $limit: Int
    $nextToken: String
  ) {
    listTransfers(limit: $limit, nextToken: $nextToken) {
      items {
        id
        toBankName
        toAccountNumber
        toAccountName
        note
        transferValue
        serviceFee
        total
        currency
        transferState
        completedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listTransferEvents = /* GraphQL */ `
  query ListTransferEvents(
    $filter: TransferEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransferEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        transferId
        id
        transferType
        event
        createdAt
        updatedAt
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getBank = /* GraphQL */ `
  query GetBank($name: String!, $country: String!) {
    getBank(name: $name, country: $country) {
      country
      name
      providerCodeMap
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const listBanks = /* GraphQL */ `
  query ListBanks(
    $country: String!
    $limit: Int
    $nextToken: String
  ) {
    listBanks(
      country: $country
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        country
        name
        providerCodeMap
      }
      nextToken
      startedAt
    }
  }
`;
export const listFees = /* GraphQL */ `
  query ListFees($country: String!) {
    listFees(country: $country) {
      country
      id
      feeStructure
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const listTransferTotals = /* GraphQL */ `
  query ListTransferTotals {
    listTransferTotals {
      ownerId
      expiresAt
      timePeriodType
      total
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const verifyBankAccount = /* GraphQL */ `
  query VerifyBankAccount(
    $bankCode: String!
    $accountNumber: String!
    $paymentProvider: PaymentProviderEnum
  ) {
    verifyBankAccount(
      bankCode: $bankCode
      accountNumber: $accountNumber
      paymentProvider: $paymentProvider
    ) {
      isValid
      accountName
    }
  }
`;
