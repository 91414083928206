import {Authenticator} from "@aws-amplify/ui-react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Layout} from "./components/Layout";
import {Home} from "./components/Home";
import {RequireAuth} from "./RequireAuth";
import {SignIn} from "./components/SignIn";
import {Payments} from "./components/Payments";
import {ComingSoon} from "./components/ComingSoon";
import {ActionModal} from "./components/ActionModal";
import {Transfer} from "./components/Transfer";
import './App.css';
import {Payment} from "./components/Payment";
import {PaymentO} from "./components/PaymentO";
import {TransferStatus} from "./components/TransferStatus";
import {Wallet} from "./components/Wallet";

function MyRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={
                        <RequireAuth>
                            <Home/>
                        </RequireAuth>
                    }
                    />
                    <Route
                        path="/payments"
                        element={
                            <RequireAuth>
                                <Payments/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/wallet"
                        element={
                            <RequireAuth>
                                <Wallet/>
                            </RequireAuth>
                        }
                    />
                    <Route path="/sign-in" element={<SignIn isSignUp={false}/>}/>
                    <Route path="/sign-up" element={<SignIn isSignUp={true}/>}/>
                </Route>
                <Route path="/action" element={<ActionModal/>}>
                    <Route path="transfer" element={
                        <RequireAuth>
                            <Transfer/>
                        </RequireAuth>
                    }>
                    </Route>
                    <Route path="transfer/*" element={
                        <RequireAuth>
                            <TransferStatus/>
                        </RequireAuth>
                    }/>
                    <Route path="payment" element={
                        <RequireAuth>
                            <Payment/>
                        </RequireAuth>
                    }/>
                    <Route path="payment-o" element={
                        <RequireAuth>
                            <PaymentO/>
                        </RequireAuth>
                    }/>
                    <Route path="coming-soon" element={
                        <ComingSoon/>
                    }/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}


function App() {
    return (
        <Authenticator.Provider>
            <MyRoutes/>
        </Authenticator.Provider>
    );
}

export default App;