import {Button, View} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";
import {Icons} from "../ui-components";

export function SideNav() {
    const navigate = useNavigate();

    return (
        <View>
            <Button isFullWidth={true} marginBottom={"1.5rem"} variation={"primary"}
                    onClick={() => navigate('/action/transfer')}>
                Send money
            </Button>
            <Button isFullWidth={true} marginBottom={"1.5rem"} variation="menu" gap="1rem"
                    onClick={() => navigate('/')}>
                <Icons width={28} height={28}/>
                Home
            </Button>
            <Button isFullWidth={true} marginBottom={"1.5rem"} variation="menu" gap="1rem"
                    onClick={() => navigate('/payments')}>
                <Icons width={28} height={28} type={"transfer"}/>
                Payments
            </Button>
            <Button isFullWidth={true} marginBottom={"1.5rem"} variation="menu" gap="1rem"
                    onClick={() => navigate('/wallet')}>
                <Icons width={28} height={28} type={"wallet"}/>
                Wallet
            </Button>
            {/*<Button isFullWidth={true} marginBottom={"1.5rem"} variation="menu" gap="1rem"*/}
            {/*        onClick={() => navigate('/action/coming-soon')}>*/}
            {/*    <Icons width={28} height={28} type={"settings"}/>*/}
            {/*    Settings*/}
            {/*</Button>*/}
        </View>
    )
}