import {Outlet} from 'react-router-dom';
import {useAuthenticator, View, Flex} from '@aws-amplify/ui-react';
import {TopNav} from "./TopNav";
import {SideNav} from "./SideNav";

export function Layout() {
    const {route} = useAuthenticator((context) => [
        context.route,
    ]);

    return (
        <>
            <TopNav></TopNav>

            <Flex
                direction={{base: 'column', medium: 'row',}}
                justifyContent="stretch"
                alignItems="flex-start"
                alignContent="flex-start"
                minHeight={"75vh"}
                wrap="nowrap"
                gap="1rem"
                maxWidth={"1440px"}
                margin="50px auto"
            >
                {route !== 'authenticated' ? (
                    <></>
                    // <View width="100%" order={3}>
                    //     <CreateAccountBanner padding={"0 24px"} margin={"0 auto"}></CreateAccountBanner>
                    // </View>
                ) : (
                    <View display={{base: 'none', medium: 'inherit'}} width="220px" order={1} margin={"8px 24px"}>
                        <SideNav></SideNav>
                    </View>
                )}
                <View width="100%" order={2}>
                    <View maxWidth={"600px"} padding={"0 24px"} margin={"0 auto"}>

                        <Outlet/>

                    </View>
                </View>
            </Flex>
            <View textAlign={"center"}>
                <footer>© 2023 Dexo Fin Ltd. All rights reserved. Version {process.env.REACT_APP_VERSION}</footer>
            </View>
        </>
    );
}