/* eslint-disable */
import * as React from "react";
import {getOverrideProps} from "@aws-amplify/ui-react/internal";
import TransferCard from "./TransferCard";
import {Collection, Placeholder} from "@aws-amplify/ui-react";
import {listTransfers} from "../graphql/queries";
import {API, graphqlOperation} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import {currencyFromCode, formatHumanDate, stateToIcon} from "./utils";

export default function TransferCardList(props) {
    const {items: itemsProp, limit = 3, overrideItems, overrides, ...rest} = props;
    const [items, setItems] = React.useState(undefined);
    const navigate = useNavigate();

    async function getItems() {
        try {
            const gqlData = await API.graphql(graphqlOperation(listTransfers, {limit: limit}));
            const gqlItems = gqlData.data.listTransfers.items;
            setItems(gqlItems);
        } catch (err) {
            console.log('error fetching items', err)
        }
    }

    React.useEffect(() => {
        if (itemsProp !== undefined) {
            setItems(itemsProp);
            return;
        }
        getItems();
    }, [itemsProp]);


    if (typeof items === 'undefined') {
        return (
            <>
                <Placeholder size="large" height={"77px"} margin={"16px 0"}/>
                <Placeholder size="large" height={"77px"} margin={"16px 0"} />
                <Placeholder size="large" height={"77px"} margin={"16px 0"} />
            </>
        );
    }

    return (
        <Collection
            type="list"
            direction="column"
            justifyContent="left"
            items={items || []}
            {...getOverrideProps(overrides, "TransferCardList")}
            {...rest}
        >
            {(item, index) => (
                <TransferCard
                    amount={`${currencyFromCode(item.currency)}${item.total}`}
                    transferState={item.transferState}
                    title={item.toAccountName}
                    description={`${formatHumanDate(item.createdAt)} · ${item.note ?? ''}`}
                    key={item.id}
                    icon={stateToIcon(item.transferState)}
                    width={"auto"}
                    onClick={() => navigate(`/action/transfer/${item.id}`)}
                    {...(overrideItems && overrideItems({item, index}))}
                ></TransferCard>
            )}
        </Collection>
    );
}
