import {Heading, View} from '@aws-amplify/ui-react';
import {ActionCard, TransferCardList} from "../ui-components";
import {useNavigate} from "react-router-dom";

export function Payments() {
    const navigate = useNavigate();

    return (
        <>
            <Heading level={1}>Payments</Heading>
            <View marginTop={"medium"}>
                <TransferCardList limit={100} searchNoResultsFound={
                    <ActionCard
                        icon={"transfer"}
                        label={"You have not made any transfers yet. Let's get you started."}
                        buttonLabel={"Send money"}
                        action={() => navigate('/action/transfer')}
                        width={"auto"}
                        marginTop={"medium"}></ActionCard>
                }></TransferCardList>
            </View>
        </>
    );
}