/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {Button, Flex, Image, View} from "@aws-amplify/ui-react";
export default function NavBar(props) {
  const { action, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        PayfricaLogo: {},
        Button: {},
        "Frame 321": {},
        "Frame 28": {},
        "Rectangle 1164": {},
        NavBar: {},
      },
      variantValues: { state: "signed-out" },
    },
    {
      overrides: {
        PayfricaLogo: {},
        Button: { children: "Sign out" },
        "Frame 321": {},
        "Frame 28": {},
        "Rectangle 1164": {},
        NavBar: {},
      },
      variantValues: { state: "signed-in" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="1440px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "NavBar")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="18px 24px 18px 24px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 28")}
      >


        {/*<PayfricaLogo*/}
        {/*  width="220px"*/}
        {/*  height="43px"*/}
        {/*  display="block"*/}
        {/*  gap="unset"*/}
        {/*  alignItems="unset"*/}
        {/*  justifyContent="unset"*/}
        {/*  shrink="0"*/}
        {/*  position="relative"*/}
        {/*  padding="0px 0px 0px 0px"*/}
        {/*  colour="default"*/}
        {/*  {...getOverrideProps(overrides, "PayfricaLogo")}*/}
        {/*></PayfricaLogo>*/}

        <Image alt={"Dexo"}
               src={"/assets/dexo-lockup.svg"}
               height={"37px"}
        ></Image>

        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 321")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="link"
            children="Sign in"
            onClick={action}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
      </Flex>
      <View
        width="unset"
        height="1px"
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(217,217,217,1)"
        {...getOverrideProps(overrides, "Rectangle 1164")}
      ></View>
    </Flex>
  );
}
