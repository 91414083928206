import {Outlet, useNavigate} from 'react-router-dom';
import {Button, View, Flex} from '@aws-amplify/ui-react';
import {Icons} from "../ui-components";
import {TopNav} from "./TopNav";

export function ActionModal() {
    const navigate = useNavigate();

    return (
        <>
            <TopNav showNav={false}></TopNav>

            <Flex
                direction={{base: 'column', medium: 'row'}}
                justifyContent="stretch"
                alignItems="flex-start"
                alignContent="flex-start"
                minHeight={"75vh"}
                wrap="nowrap"
                gap="1rem"
                maxWidth={"1440px"}
                margin="50px auto"
            >
                <View display={{base: 'none', medium: 'inherit'}} textAlign={"end"} width="220px"
                      margin={{base: "-90px 0 0 0", medium: "4px 24px"}}>
                </View>
                <View width="100%">
                    <View position={"relative"} maxWidth={"600px"} padding={"0 24px"} margin={"0 auto"}
                          overflow={"display"}>
                        <Button variation={"menu"} position={"absolute"} left={{base: '10px', medium: '-100px'}}
                                top={{base: '-40px', medium: '6px'}} size={"small"} onClick={() => navigate('/')}>
                            <Icons width={36} height={36} type={"back"}/>
                        </Button>

                        <Outlet/>

                    </View>
                </View>
            </Flex>
            <View textAlign={"center"}>
                <footer>© 2023 Dexo Fin Ltd. All rights reserved.</footer>
            </View>
        </>
    );
}