import {useEffect} from "react";
import {Authenticator, Button, Heading, useAuthenticator, useTheme, View} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {useLocation, useNavigate} from "react-router-dom";


export function SignIn(props) {
    const {
        route,
        toSignIn,
        toSignUp,
        toResetPassword
    } = useAuthenticator((context) => [context.route, context.toSignIn, context.toSignUp, context.toResetPassword]);
    const location = useLocation();
    const navigate = useNavigate();
    const {isSignUp = false} = props || {};
    let from = location.state?.from?.pathname || '/';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, {replace: true});
        }
    }, [route, navigate, from]);

    useEffect(() => {
        if (isSignUp) {
            toSignUp();
        } else {
            toSignIn();
        }
    }, [isSignUp]);

    console.log("isSignUp",isSignUp);

    const components = {
        SignIn: {
            Header() {
                const {tokens} = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={1}
                    >
                        Sign in
                    </Heading>
                );
            },
            Footer() {
                return (
                    <View data-amplify-footer>
                        <Button size={"small"} variation={"link"} onClick={() => navigate('/sign-up')}>Create an account</Button>
                        <Button size={"small"} variation={"link"} onClick={toResetPassword}>Forgot your password?</Button>
                    </View>
                )
            }
        },
        SignUp: {
            Header() {
                const {tokens} = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={1}
                    >
                        Create an account
                    </Heading>
                );
            },
            Footer() {
                return (
                    <View data-amplify-footer>
                        <Button size={"small"} variation={"link"} onClick={() => navigate('/sign-in')}>Already have an account? Sign in</Button>
                    </View>
                )
            }
        },
        ResetPassword: {
            Header() {
                return (
                    <Heading
                        padding={`0`}
                        level={1}
                    >
                        Reset password
                    </Heading>
                );
            },
        }
    };

    const formFields = {
        signUp: {
            given_name: {
                order: 1
            },
            family_name: {
                order: 2
            },
            email: {
                order:3
            },
            password: {
                order: 4
            },
            confirm_password: {
                order: 5
            },
            // phone_number: {
            //     dialCode: '+234',
            //     order: 6
            // },
        },
    }

    return (
        <View className="auth-wrapper">
            <Authenticator components={components} formFields={formFields} initialState={isSignUp ? "signUp" : "signIn"}></Authenticator>
        </View>
    );
}
