import * as React from "react";
import {
    Alert,
    Autocomplete,
    Button,
    Divider,
    FieldGroupIcon,
    Flex,
    Heading,
    Text,
    TextField
} from '@aws-amplify/ui-react';
import {useState} from 'react';
import {validateField} from "../ui-components/utils";
import {API, graphqlOperation} from "aws-amplify";
import {listBanks, verifyBankAccount} from "../graphql/queries";
import {createTransfer} from "../graphql/mutations";
import {useLocation, useNavigate} from "react-router-dom";

export function Transfer() {
    const {state} = useLocation();
    console.log('state', state);
    const {paymentProvider = 'FLUTTERWAVE', bankNamePrefill = '', accountNumberPrefill} = state ?? {};

    const navigate = useNavigate();

    const [totalCost, setTotalCost] = useState(0);
    const [transferFee, setTransferFee] = useState(0);
    const [transferValue, setTransferValue] = useState(0);
    const [toAccountNumber, setToAccountNumber] = useState(accountNumberPrefill);
    const [toAccountName, setToAccountName] = useState(null);
    const [toBankCode, setToBankCode] = useState('');
    const [toBankName, setToBankName] = useState(bankNamePrefill);
    const [note, setNote] = useState(null);
    const [bankOptions, setBankOptions] = useState([]);
    const [errorList, setErrorList] = React.useState({});
    const [exception, setException] = React.useState(null);
    const [banksLoading, setBanksLoading] = React.useState(true);

    const calcFee = val => {
        let transferAmount = Number(val);
        let fee = Math.round(transferAmount * 5.0 / 100);
        setTransferValue(transferAmount)
        setTransferFee(fee);
        setTotalCost(transferAmount + fee);
        console.log(`calcFee ${val} => ${totalCost}`, val);
    };

    const validations = {
        toBankCode: [{type: "Required", validationMessage: "Please select a bank"}],
        toAccountNumber: [{type: "Required"}, {
            type: "Account",
            validationMessage: "Please enter a valid account number"
        }],
        toAccountName: [{type: "Required", validationMessage: "Please enter a valid account number"}],
        note: [],
        transferValue: [{type: "Required"}, {
            type: "GreaterThanNum",
            numValues: [50],
            validationMessage: "Minimum transfer amount is 50"
        }],
    };

    const runValidationTasks = async (
        fieldName,
        currentValue,
        getDisplayValue
    ) => {
        const value =
            currentValue && getDisplayValue
                ? getDisplayValue(currentValue)
                : currentValue;
        let validationResponse = validateField(value, validations[fieldName]);
        setErrorList((errors) => ({...errors, [fieldName]: validationResponse}));
        return validationResponse;
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        let modelFields = {
            toBankCode,
            toBankName,
            toAccountNumber,
            toAccountName,
            note,
            transferValue
        };
        const validationResponses = await Promise.all(
            Object.keys(validations).reduce((promises, fieldName) => {
                if (Array.isArray(modelFields[fieldName])) {
                    promises.push(
                        ...modelFields[fieldName].map((item) =>
                            runValidationTasks(fieldName, item)
                        )
                    );
                    return promises;
                }
                promises.push(
                    runValidationTasks(fieldName, modelFields[fieldName])
                );
                return promises;
            }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
            return;
        }
        try {
            Object.entries(modelFields).forEach(([key, value]) => {
                if (typeof value === "string" && value.trim() === "") {
                    modelFields[key] = undefined;
                }
            });
            const modelFieldsToSave = {
                toAccountNumber: modelFields.toAccountNumber,
                toAccountName: modelFields.toAccountName,
                toBankCode: modelFields.toBankCode,
                toBankName: modelFields.toBankName,
                note: modelFields.note,
                transferValue: modelFields.transferValue,
                paymentProvider: paymentProvider,
                currency: 'NGN',
            };

            const gqlData = await API.graphql(graphqlOperation(createTransfer, {input: modelFieldsToSave}));
            if (gqlData.data.createTransfer?.id) {
                //we have a valid response. Let's proceed to payment
                console.log(gqlData.data.createTransfer);
                switch (paymentProvider) {
                    case "FLUTTERWAVE":
                        navigate('/action/payment', {state: gqlData.data.createTransfer});
                        break;
                    case "OPAY":
                        navigate('/action/payment-o', {state: gqlData.data.createTransfer});
                        break;
                    default:
                        setException('Unsupported payment method.');
                        break;
                }

            } else {
                setException('Unable to transfer at this time');
            }

        } catch (err) {
            setException(err);
        }
    }

    const errorToHtml = (obj) => {
        if (!obj || obj === {}) {
            return null;
        }
        let elms = [];
        for (let k of Object.keys(obj)) {
            if (obj[k].hasError) {
                elms.push(<li>{obj[k].errorMessage}</li>);
            }
        }
        return (
            <ul>
                {elms}
            </ul>
        );
    }

    const getBanks = async () => {
        try {
            const gqlData = await API.graphql(graphqlOperation(listBanks, {country: 'NG', limit: 600}));
            setBankOptions(gqlData.data.listBanks.items
                .map(e => ({id: JSON.parse(e.providerCodeMap)[paymentProvider], label: e.name}))
                .filter(e => e.id !== undefined)
            );
            setBanksLoading(false);
        } catch (err) {
            console.log('error fetching items', err);
        }
    }

    const lookupAccount = async () => {
        try {
            console.log(`looking up ${toBankCode}: ${toAccountNumber}`)
            let validation = await Promise.all([
                validateField(toAccountNumber, validations.toAccountNumber),
                validateField(toBankCode, validations.toBankCode)
            ]);

            if (validation.some(e => e.hasError)) {
                setToAccountName('');
                return;
            }

            const gqlData = await API.graphql(graphqlOperation(verifyBankAccount, {
                bankCode: toBankCode,
                accountNumber: toAccountNumber,
                paymentProvider: paymentProvider
            }));
            console.log('gqlData.data: ', gqlData.data);

            if (gqlData.data?.verifyBankAccount?.isValid) {
                setToAccountName(gqlData.data.verifyBankAccount.accountName)
            } else {
                setToAccountName('');
            }
        } catch (err) {
            console.log('error fetching items', err);
        }
    }

    React.useEffect(() => {
        console.log('looking up bank')
        const getData = setTimeout(async () => {
            await lookupAccount();
        }, 1250);

        return () => clearTimeout(getData);
    }, [toAccountNumber, toBankCode])

    React.useEffect(() => {
        getBanks();
    }, []);

    //auto lookup bank when prefilling bank details.
    React.useEffect(() => {
        if (bankOptions.length > 0 && accountNumberPrefill && bankNamePrefill) {
            let option = bankOptions.find((e) => e.label === bankNamePrefill);
            if (option) {
                setToBankName(option.label);
                setToBankCode(option.id);
            }
        }
    }, [bankNamePrefill, accountNumberPrefill, bankOptions]);

    return (
        <>
            <Heading level={1}>Transfer</Heading>
            <Flex
                gap="24px"
                as="form"
                direction="column"
                width="auto"
                justifyContent="flex-start"
                alignItems="stretch"
                position="relative"
                padding="24px 0px 24px 0px"
                onSubmit={onSubmit}
            >
                <Autocomplete
                    label="Bank"
                    onSelect={(option) => {
                        const {label, id} = option;
                        console.log('onSelect', option);
                        setToBankCode(id);
                        setToBankName(label);
                    }}
                    onClear={() => {
                        console.log('onClear');
                        setToBankCode('');
                        setToBankName('');
                    }}
                    onChange={(event) => {
                        console.log('onChange', event.target.value);
                        let option = bankOptions.find((e) => e.label === event.target.value);
                        if (option) {
                            setToBankName(option.label);
                            setToBankCode(option.id);
                        } else {
                            setToBankName(event.target.value);
                        }
                    }}
                    options={bankOptions}
                    value={toBankName}
                    placeholder="Select bank"
                    isLoading={banksLoading}
                    isRequired={true}
                ></Autocomplete>
                <TextField
                    label="Account Number"
                    onChange={e => setToAccountNumber(e.target.value)}
                    placeholder="eg. '0123456'"
                    value={toAccountNumber}
                    isRequired={true}
                ></TextField>
                <TextField
                    label="Account Name"
                    placeholder=""
                    isDisabled={true}
                    errorMessage={"blah"}
                    value={toAccountName}
                ></TextField>
                <TextField
                    label="Optional note"
                    onChange={e => setNote(e.target.value)}
                    placeholder="eg. 'Blame it on the boogie'"
                ></TextField>
                <Divider
                    height="1px"
                    size="small"
                    orientation="horizontal"
                ></Divider>
                <TextField
                    label="Amount"
                    placeholder="0"
                    size="large"
                    innerStartComponent={<FieldGroupIcon>₦</FieldGroupIcon>}
                    type={"number"}
                    onInput={(e) => calcFee(e.currentTarget.value)}
                ></TextField>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Text
                        textAlign="left"
                        display="block"
                        direction="column"
                        position="relative"
                        whiteSpace="pre-wrap"
                    >{"Fee"}</Text>
                    <Text
                        textAlign="left"
                        display="block"
                        direction="column"
                        position="relative"
                        whiteSpace="pre-wrap"
                    >₦ {transferFee.toFixed(2)}</Text>
                </Flex>
                <Divider
                    height="1px"
                    size="small"
                    orientation="horizontal"
                ></Divider>
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Text
                        fontSize={"large"}
                        display="block"
                        direction="column"
                        whiteSpace="pre-wrap"
                    >{"Total"}</Text>
                    <Text
                        fontSize={"large"}
                        display="block"
                        direction="column"
                        whiteSpace="pre-wrap"
                    >₦ {totalCost.toFixed(2)}</Text>
                </Flex>
                {Object.values(errorList).some(e => e.hasError) && (
                    <Alert variation="error" alignSelf={"stretch"} heading={"Please check the following issues:"}>
                        {errorToHtml(errorList)}
                    </Alert>
                )}
                {exception !== null && (
                    <Alert variation="error" heading={"Failed to save"}>
                        {exception}
                    </Alert>
                )}
                <Button
                    width="unset"
                    height="unset"
                    shrink="0"
                    alignSelf="flex-end"
                    size="large"
                    type="submit"
                    isDisabled={false}
                    variation="primary"
                    children="Start Transfer"
                ></Button>
                {/*<Button*/}
                {/*    width="unset"*/}
                {/*    height="unset"*/}
                {/*    shrink="0"*/}
                {/*    alignSelf="flex-end"*/}
                {/*    size="large"*/}
                {/*    isDisabled={false}*/}
                {/*    variation="primary"*/}
                {/*    children="Start Flutterwave"*/}
                {/*    onClick={() => navigate('/action/payment', {state:{id:98765456789,total: 109, currency:'NGN'}})}*/}
                {/*></Button>*/}
            </Flex>
        </>
    );
}