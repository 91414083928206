import {Button, Flex, Heading, View} from '@aws-amplify/ui-react';
import {useNavigate} from "react-router-dom";
import {ActionCard, TransferCardList} from "../ui-components";
import * as React from "react";

export function Home() {
    const navigate = useNavigate();

    return (
        <>
            <Flex
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Heading display={"block"} position={"relative"} width={"auto"} level={1}>
                    Home
                </Heading>
            </Flex>
            <View marginTop={"xl"}>
                <Flex justifyContent={"space-between"}>
                    <Heading level={5}>Latest Payments</Heading>
                    <Button
                        shrink="0"
                        size="small"
                        variation="link"
                        marginRight={"-12px"}
                        children="See all"
                        onClick={() => navigate('/payments')}
                    ></Button>
                </Flex>
                <View marginTop={"medium"}>
                    <TransferCardList searchNoResultsFound={
                        <ActionCard
                            icon={"transfer"}
                            label={"You have not made any transfers yet. Let's get you started."}
                            buttonLabel={"Send money"}
                            action={() => navigate('/action/transfer')}
                            width={"auto"}
                        ></ActionCard>
                    }></TransferCardList>
                </View>
            </View>
            {/*<View marginTop={"xl"}>*/}
            {/*    <Heading level={5}>Invitations</Heading>*/}
            {/*    <ActionCard*/}
            {/*        icon={"add-user"}*/}
            {/*        label={"Invite friends to Payfrica and enjoy special discounts on future transfers."}*/}
            {/*        buttonLabel={"Get started"}*/}
            {/*        action={() => navigate('/action/coming-soon')}*/}
            {/*        width={"auto"}*/}
            {/*        marginTop={"medium"}*/}
            {/*    ></ActionCard>*/}
            {/*    <View marginTop={"medium"}>*/}
            {/*        <InvitationCardList searchNoResultsFound={<></>}></InvitationCardList>*/}
            {/*    </View>*/}
            {/*</View>*/}
        </>

    );
}