/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {getOverrideProps} from "@aws-amplify/ui-react/internal";
import Icons from "./Icons";
import {Flex, Text} from "@aws-amplify/ui-react";

export default function TransferCard(props) {
    const {
        amount = "$0.00",
        title = "Title",
        transferState = "PENDING",
        description = "Description",
        icon = "tick",
        overrides,
        ...rest
    } = props;
    let isCancelled = transferState === 'CANCELLED';
    return (
        <Flex
            gap="16px"
            direction="column"
            width="461px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
            padding="16px 0px 16px 0px"
            style={{cursor: "pointer"}}
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "TransferCard")}
            {...rest}
        >
            <Flex
                as="button"
                className="button_hover_bg"
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 417")}
            >
                <Icons
                    width="36px"
                    height="36px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    type={icon}
                    {...getOverrideProps(overrides, "Icons")}
                ></Icons>
                <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Product Title")}
                >
                    <Text
                        fontFamily="Ubuntu"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(48,64,80,1)"
                        lineHeight="24px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children={title}
                        {...getOverrideProps(overrides, "A. Ambrose")}
                    ></Text>
                    <Text
                        fontFamily="Ubuntu"
                        fontSize="14px"
                        fontWeight="400"
                        color="rgba(92,102,112,1)"
                        lineHeight="21px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children={description}
                        {...getOverrideProps(overrides, "15:11 \u00B7 Card payment")}
                    ></Text>
                </Flex>
                <Text
                    fontFamily="Ubuntu"
                    fontSize="20px"
                    fontWeight="400"
                    color="rgba(48,64,80,1)"
                    lineHeight="30px"
                    textAlign="right"
                    display="block"
                    direction="column"
                    textDecoration={isCancelled ? "line-through" : "unset"}
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={amount}
                    {...getOverrideProps(overrides, "$99")}
                ></Text>
            </Flex>
        </Flex>
    );
}
