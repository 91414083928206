import * as React from "react";
import {
    Button,
    Flex,
    Heading, Placeholder, Table, TableBody, TableCell, TableRow
} from '@aws-amplify/ui-react';
import {useLocation, useNavigate} from "react-router-dom";
import {ActionCard} from "../ui-components";
import {useState} from "react";
import {API, graphqlOperation} from "aws-amplify";
import {getTransfer} from "../graphql/queries";
import {currencyFromCode, formatHumanDate} from "../ui-components/utils";

export function TransferStatus(props) {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [pathId, pathReturned] = pathname.replace('/action/transfer/', '').split('/');
    const [transfer, setTransfer] = useState(null);

    const getData = async (transferId) => {
        try {
            if (!transferId) {
                return;
            }
            const gqlData = await API.graphql(graphqlOperation(getTransfer, {
                id: transferId
            }));

            if (gqlData.data) {
                setTransfer(gqlData.data.getTransfer)
            } else {
                setTransfer(null);
            }
        } catch (err) {
            console.log('error fetching data', err);
        }
    }

    React.useEffect(() => {
        //get transfer
        getData(pathId);
    }, [pathId])


    return (
        <>
            {!transfer &&
                <Placeholder size="large" height={"36px"} margin={"16px 0"}/>
            }
            {transfer &&
                <>
                    <Heading
                        level={1}>{currencyFromCode(transfer.currency)}{transfer.transferValue} to {transfer.toAccountName}</Heading>
                    <Heading level={5}>{formatHumanDate(transfer.createdAt)}</Heading>
                </>
            }

            <Flex
                gap="24px"
                direction="column"
                width="auto"
                justifyContent="flex-start"
                alignItems="stretch"
                position="relative"
                padding="24px 0px 24px 0px"
            >
                {transfer && (transfer.transferState === 'PENDING' || transfer.transferState === 'PROCESSING') &&
                    <ActionCard
                        icon={"clock"}
                        label={"Your transfer is in progress. You will be notified when the transfer is complete."}
                        buttonLabel={"Close"}
                        action={() => navigate('/')}
                        width={"auto"}
                    ></ActionCard>
                }
                {transfer && transfer.transferState === 'RECEIVE_ERROR' &&
                    <ActionCard
                        icon={"warning"}
                        label={`There was a problem receiving funds. If you have successfully completed payment then this will transfer will complete shortly. Your transfer reference is ${transfer.id}`}
                        buttonLabel={"Close"}
                        action={() => navigate('/')}
                        width={"auto"}
                    ></ActionCard>
                }
                {transfer && transfer.transferState === 'SEND_ERROR' &&
                    <ActionCard
                        icon={"warning"}
                        label={`There was a problem completing your transfer and is currently being resolved. Your transfer reference is ${transfer.id}`}
                        buttonLabel={"Close"}
                        action={() => navigate('/')}
                        width={"auto"}
                    ></ActionCard>
                }
                {transfer && transfer.transferState === 'COMPLETE' &&
                    <ActionCard
                        icon={"tick"}
                        label={`Your transfer is complete. Your transfer reference is ${transfer.id}`}
                        buttonLabel={"Close"}
                        action={() => navigate('/')}
                        width={"auto"}
                    ></ActionCard>
                }
                {transfer &&
                    <>
                        <Flex justifyContent={"space-between"}>
                            <Heading level={5}>Account details</Heading>
                            <Button
                                shrink="0"
                                size="small"
                                variation="link"
                                marginRight={"-12px"}
                                children="Transfer again"
                                onClick={() => navigate('/action/transfer', {
                                    state: {
                                        bankNamePrefill: transfer.toBankName,
                                        accountNumberPrefill: transfer.toAccountNumber
                                    }
                                })}
                            ></Button>
                        </Flex>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Account name</TableCell>
                                    <TableCell textAlign={"right"}>{transfer.toAccountName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bank</TableCell>
                                    <TableCell textAlign={"right"}>{transfer.toBankName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Account number</TableCell>
                                    <TableCell textAlign={"right"}>{transfer.toAccountNumber}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Heading level={5}>Transfer details</Heading>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Note</TableCell>
                                    <TableCell textAlign={"right"}>{transfer.note ?? 'not set'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Transfer amount</TableCell>
                                    <TableCell
                                        textAlign={"right"}>{currencyFromCode(transfer.currency)}{transfer.transferValue}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Fee</TableCell>
                                    <TableCell
                                        textAlign={"right"}>{currencyFromCode(transfer.currency)}{transfer.serviceFee}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell
                                        textAlign={"right"}>{currencyFromCode(transfer.currency)}{transfer.total}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>

                }
                {!transfer &&
                    <Placeholder size="large" height={"144px"} margin={"16px 0"}/>
                }
            </Flex>
        </>
    );
}