import {Heading, View} from '@aws-amplify/ui-react';
import {ActionCard} from "../ui-components";
import {useNavigate} from "react-router-dom";

export function Wallet() {
    const navigate = useNavigate();

    return (
        <>
            <Heading level={1}>Wallet</Heading>
            <View marginTop={"medium"}>
                <ActionCard
                    icon={"wallet"}
                    label={"Soon you will be able to prefund our account for instant payments with increased privacy."}
                    buttonLabel={"Get Started"}
                    action={() => navigate('/action/coming-soon')}
                    width={"auto"}
                    marginTop={"medium"}></ActionCard>
            </View>
        </>
    );
}