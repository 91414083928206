/* eslint-disable */
import {createTheme} from "@aws-amplify/ui-react";
import {studioTheme} from "./";

export const studioThemeOverride = createTheme({
        name: "studioThemeOverride",
        tokens: {
            components: {
                authenticator: {
                    maxWidth: {value: "60rem"},
                    container: {widthMax: {value: "30rem"}},
                    router: {
                        borderStyle: {value: "none"},
                        boxShadow: {value: "none"},
                    },
                },
                button: {
                    fontWeight: {value: "normal"},
                    borderRadius: {value: "{radii.xl.value}"},
                    _hover: {
                        color: {value: "{colors.font.primary.value}"},
                        backgroundColor: {value: "{colors.background.tertiary.value}"},
                    },
                    _active: {
                        color: {value: "{colors.font.primary.value}"},
                        backgroundColor: {value: "{colors.background.tertiary.value}"},
                    },
                    _focus: {
                        color: {value: "{colors.font.primary.value}"},
                        backgroundColor: {value: "{colors.background.tertiary.value}"},
                    },
                    menu: {
                        _hover: {
                            color: {value: "{colors.font.primary.value}"},
                            backgroundColor: {value: "{colors.background.tertiary.value}"},
                        },
                        _active: {
                            color: {value: "{colors.font.primary.value}"},
                            backgroundColor: {value: "{colors.background.tertiary.value}"},
                        },
                        _focus: {
                            color: {value: "{colors.font.primary.value}"},
                            backgroundColor: {value: "{colors.background.tertiary.value}"},
                        },
                    },
                    link: {
                        _hover: {
                            color: {value: "{colors.font.primary.value}"},
                            backgroundColor: {value: "{colors.background.tertiary.value}"},
                        },
                    }
                },
                menu: {
                    borderStyle: {value: "none"}
                },
                divider: {
                    borderWidth: {value: "1px"}
                }
            },
            fonts: {
                default: {
                    variable: {
                        value:
                            "Ubuntu, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Oxygen, Cantarell, 'Open Sans', sans-serif",
                    },
                    static: {
                        value:
                            "Ubuntu, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Oxygen, Cantarell, 'Open Sans', sans-serif",
                    },
                },
            },
            radii: {
                xs: {value: "0.25rem"},
                small: {value: "0.5rem"},
                medium: {value: "1rem"},
                large: {value: "1.5rem"},
                xl: {value: "2rem"},
                xxl: {value: "4rem"},
                xxxl: {value: "8rem"},
            },
        },
    },
    studioTheme);
