import * as React from "react";
import {
    Flex,
    Heading
} from '@aws-amplify/ui-react';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {ActionCard} from "../ui-components";
import {useState} from "react";

export function PaymentO(props) {
    const {state, pathname} = useLocation();
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = useState(pathname.endsWith('success'));
    const [isPaused, setIsPaused] = useState(pathname.endsWith('return'));
    const [paymentLink, setPaymentLink] = useState(state?.paymentLink);

    const doRedirect = () => {
        if (paymentLink) {
            window.location.href = paymentLink;
        }
    }

    React.useEffect(() => {
        console.log('isSuccess', isSuccess);
        console.log('isPaused', isPaused);
        console.log('paymentLink', paymentLink);

        if (!isSuccess && !isPaused) {
            doRedirect();
        }
    }, [paymentLink]);

    return (
        <>
            <Heading level={1}>Payment</Heading>
            <Flex
                gap="24px"
                direction="column"
                width="auto"
                justifyContent="flex-start"
                alignItems="stretch"
                position="relative"
                padding="24px 0px 24px 0px"
            >
                {isPaused &&
                    <ActionCard
                        icon={"warning"}
                        label={"Your transfer is incomplete. Would you like to continue with payment?"}
                        buttonLabel={"Continue with payment"}
                        action={() => doRedirect()}
                        width={"auto"}
                    ></ActionCard>
                }
                {isSuccess &&
                    <ActionCard
                        icon={"tick"}
                        label={"Your transfer is in progress. You will be notified when the transfer is complete."}
                        buttonLabel={"Close"}
                        action={() => navigate('/')}
                        width={"auto"}
                    ></ActionCard>
                }
                {!isSuccess && !isPaused &&
                    <ActionCard
                        icon={"transfer"}
                        label={"The payment screen should appear. If not, please try opening here."}
                        buttonLabel={"Open Payment"}
                        action={() => doRedirect()}
                        width={"auto"}
                    ></ActionCard>
                }
            </Flex>
        </>
    );
}