/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      email
      invitationCode
      name
      ownerId
      invitationState
      acceptedAt
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation($input: UpdateInvitationInput!) {
    updateInvitation(input: $input) {
      email
      invitationCode
      name
      ownerId
      invitationState
      acceptedAt
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      email
      invitationCode
      name
      ownerId
      invitationState
      acceptedAt
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const createTransfer = /* GraphQL */ `
  mutation CreateTransfer($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      id
      transferType
      ownerId
      transferTypeAndId
      toBankCode
      toBankName
      toAccountNumber
      toAccountName
      note
      transferValue
      serviceFee
      total
      currency
      transferState
      paymentProvider
      paymentLink
      completedAt
      createdAt
      updatedAt
      _deleted
    }
  }
`;
export const updateTransfer = /* GraphQL */ `
  mutation UpdateTransfer($input: UpdateTransferInput!) {
    updateTransfer(input: $input) {
      id
      transferType
      ownerId
      transferTypeAndId
      toBankCode
      toBankName
      toAccountNumber
      toAccountName
      note
      transferValue
      serviceFee
      total
      currency
      transferState
      receiveProviderFee
      sendProviderFee
      paymentProvider
      paymentLink
      completedAt
      createdAt
      updatedAt
      _deleted
    }
  }
`;
