import {Icons, NavBar} from "../ui-components";
import {Button, Divider, Flex, Image, Menu, MenuItem, useAuthenticator, View} from "@aws-amplify/ui-react";
import {IconMenu} from "@aws-amplify/ui-react/internal";
import {useNavigate} from "react-router-dom";

export function TopNav(props) {
    const {
        showNav = true,
    } = props;

    const {route, signOut} = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();

    function logOut() {
        signOut();
        navigate('/sign-in');
    }

    return (
        <>
            {route !== 'authenticated' ? (
                <NavBar display={{base: 'none', medium: 'inherit'}} state={"signed-out"} width={"auto"}
                        maxWidth={"1440px"} margin={"0 auto"} action={() => navigate('/sign-in')}></NavBar>
            ) : (
                <NavBar display={{base: 'none', medium: 'inherit'}} state={"signed-in"} width={"auto"}
                        maxWidth={"1440px"} margin={"0 auto"} action={() => logOut()}></NavBar>
            )}

            <Flex
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                minHeight={"48px"}
                display={{medium: 'none'}}
                margin="14px 24px"
            >
                <View flex={"1 1 0px"}>

                    <Image alt={"Dexo"}
                           src={"/assets/dexo-brandmark.svg"}
                           height={"37px"}
                    ></Image>

                    {/*<PayfricaBrandmark*/}
                    {/*    width="52px"*/}
                    {/*    height="37px"*/}
                    {/*    // margin="3px 0px 0px 0.5px"*/}
                    {/*></PayfricaBrandmark>*/}
                </View>
                {showNav && route === 'authenticated' && (
                    <>
                        <Button shrink={0} size={"small"} variation={"primary"}
                                onClick={() => navigate('/action/transfer')}>
                            Send money
                        </Button>
                        <Menu shrink={0} menuAlign="end" borderRadius={"medium"} trigger={
                            <Button variation="menu" fontSize={"36px"} size={"small"} margin={"0 -6px 0 0"} padding={"6px"}>
                                <IconMenu></IconMenu>
                            </Button>
                        }>
                            <MenuItem onClick={() => navigate('/')} borderRadius={"medium medium 0 0"} padding={"1rem"}>
                                <Icons width={22} height={22} marginRight={"0.75rem"}/>
                                Home
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/payments')} padding={"1rem"}>
                                <Icons width={22} height={22} type={"transfer"} marginRight={"0.75rem"}/>
                                Payments
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/wallet')} padding={"1rem"}>
                                <Icons width={22} height={22} type={"wallet"} marginRight={"0.75rem"}/>
                                Wallet
                            </MenuItem>
                            {/*<MenuItem onClick={() => navigate('/action/coming-soon')} padding={"1rem"}>*/}
                            {/*    <Icons width={22} height={22} type={"settings"} marginRight={"0.75rem"}/>*/}
                            {/*    Settings*/}
                            {/*</MenuItem>*/}
                            <Divider></Divider>
                            <MenuItem onClick={() => logOut()} borderRadius={"0 0 medium medium"} padding={"1rem"}>
                                <Icons width={22} height={22} type={"close"} marginRight={"0.75rem"}/>
                                Sign out
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Flex>
        </>
    )
}