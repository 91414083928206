import {createRoot} from "react-dom/client";
import {StrictMode} from "react";
import {ThemeProvider} from '@aws-amplify/ui-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify, Auth} from 'aws-amplify';
import awsConfig from './aws-exports';
import '@fontsource/ubuntu/300.css';
import '@aws-amplify/ui-react/styles.css';
import {studioThemeOverride} from "./ui-components/studioThemeOverride";

awsConfig.API = {
    graphql_headers: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
    })
};
awsConfig.aws_cognito_signup_attributes = [
    "GIVEN_NAME",
    "FAMILY_NAME",
    "EMAIL",
];
Amplify.configure(awsConfig);
Auth.configure({
   authenticationFlowType: 'USER_PASSWORD_AUTH',
})

const root = createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <ThemeProvider theme={studioThemeOverride}>
            <App/>
        </ThemeProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
